import { Portal } from "@chakra-ui/react";
import { Noto_Sans_JP, Noto_Serif_JP } from "next/font/google";
import { ReactNode, useRef } from "react";

const notoSansJp = Noto_Sans_JP({
  weight: ["400", "500", "700"],
  subsets: ["latin"],
  variable: "--chakra-fonts-Noto-Sans-JP",
});
const notoSerifJp = Noto_Serif_JP({
  weight: ["400", "500", "700"],
  subsets: ["latin"],
  variable: "--chakra-fonts-Noto-Serif-JP",
});

export const FontsLoader = ({ children }: { children?: ReactNode }) => {
  const ref = useRef<HTMLDivElement>(null);
  return (
    <div className={`${notoSansJp.variable} ${notoSerifJp.variable}`}>
      {/* デフォルトだと document.body 直下にモーダル等の Portal が挿入される。それだとモーダル等の内部でフォント指定が効かないため、Portal 位置を指定する  */}
      <Portal containerRef={ref}>{children}</Portal>

      <div ref={ref} />
    </div>
  );
};
