/* eslint-disable */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: string; output: string; }
};

/** Autogenerated input type of CallTestWebPush */
export type CallTestWebPushInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

/** Autogenerated input type of CancelCourse */
export type CancelCourseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  orderItemId: Scalars['String']['input'];
};

/** Autogenerated input type of CancelOrderItem */
export type CancelOrderItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  orderItemId: Scalars['String']['input'];
};

export type Catalog = {
  __typename?: 'Catalog';
  categories: Array<CatalogCategory>;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageUrl: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  ordinal: Scalars['Int']['output'];
  variations: Array<CatalogVariation>;
};

export type CatalogCategory =
  | 'Beverage'
  | 'Course'
  | 'Food';

export type CatalogSubCategory =
  | 'Appetizer'
  | 'BottleWineRed'
  | 'BottleWineRose'
  | 'BottleWineSparkling'
  | 'BottleWineWhite'
  | 'Course'
  | 'CourseOption'
  | 'Dessert'
  | 'DimSum'
  | 'GlassWine'
  | 'Handy'
  | 'LunchMenu'
  | 'LunchSideMenu'
  | 'Main'
  | 'NonAlcoholic'
  | 'NoodleRice'
  | 'OtherDrink'
  | 'Tea';

export type CatalogVariation = {
  __typename?: 'CatalogVariation';
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  ordinal: Scalars['Int']['output'];
  price: Scalars['Int']['output'];
};

/** Autogenerated input type of CompleteStaffCallRequest */
export type CompleteStaffCallRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  visitId: Scalars['String']['input'];
};

/** Autogenerated input type of CreateAuthSession */
export type CreateAuthSessionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  firebaseIdToken: Scalars['String']['input'];
};

/** Autogenerated input type of DestroyAuthSession */
export type DestroyAuthSessionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  callTestWebPush: Scalars['Boolean']['output'];
  cancelCourse: Maybe<Scalars['Boolean']['output']>;
  cancelOrderItem: Maybe<Scalars['Boolean']['output']>;
  completeStaffCallRequest: Visit;
  createAuthSession: ShopUser;
  destroyAuthSession: Scalars['Boolean']['output'];
  serveOrderItem: Maybe<Scalars['Boolean']['output']>;
  subscribeWebPush: Scalars['Boolean']['output'];
  undoOrderItem: Maybe<Scalars['Boolean']['output']>;
  unsubscribeWebPush: Scalars['Boolean']['output'];
};


export type MutationCallTestWebPushArgs = {
  input: CallTestWebPushInput;
};


export type MutationCancelCourseArgs = {
  input: CancelCourseInput;
};


export type MutationCancelOrderItemArgs = {
  input: CancelOrderItemInput;
};


export type MutationCompleteStaffCallRequestArgs = {
  input: CompleteStaffCallRequestInput;
};


export type MutationCreateAuthSessionArgs = {
  input: CreateAuthSessionInput;
};


export type MutationDestroyAuthSessionArgs = {
  input: DestroyAuthSessionInput;
};


export type MutationServeOrderItemArgs = {
  input: ServeOrderItemInput;
};


export type MutationSubscribeWebPushArgs = {
  input: SubscribeWebPushInput;
};


export type MutationUndoOrderItemArgs = {
  input: UndoOrderItemInput;
};


export type MutationUnsubscribeWebPushArgs = {
  input: UnsubscribeWebPushInput;
};

export type Order = {
  __typename?: 'Order';
  createdAt: Scalars['ISO8601DateTime']['output'];
  dailySequence: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  orderItems: Array<OrderItem>;
  visit: Visit;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  amount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
  status: OrderItemStatus;
  subCategory: CatalogSubCategory;
  variation: Maybe<Scalars['String']['output']>;
};

export type OrderItemStatus =
  | 'Canceled'
  | 'Pending'
  | 'Served';

export type Query = {
  __typename?: 'Query';
  /** Fetches a list of catalogs. */
  catalogs: Array<Catalog>;
  courses: Array<Order>;
  currentEndpointSubscribed: Scalars['Boolean']['output'];
  currentShop: Maybe<Shop>;
  orders: Array<Order>;
  shopUser: Maybe<ShopUser>;
};


export type QueryCurrentEndpointSubscribedArgs = {
  token: Scalars['String']['input'];
};

/** Autogenerated input type of ServeOrderItem */
export type ServeOrderItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  orderItemId: Scalars['String']['input'];
};

export type Shop = {
  __typename?: 'Shop';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  visits: Array<Visit>;
};


export type ShopVisitsArgs = {
  actionRequired?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ShopUser = {
  __typename?: 'ShopUser';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  shop: Shop;
};

export type StaffCallRequest = {
  __typename?: 'StaffCallRequest';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  requestType: StaffCallRequestType;
};

export type StaffCallRequestType =
  | 'CallSommelier'
  | 'CallStaff';

/** Autogenerated input type of SubscribeWebPush */
export type SubscribeWebPushInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

/** Autogenerated input type of UndoOrderItem */
export type UndoOrderItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  orderItemId: Scalars['String']['input'];
};

/** Autogenerated input type of UnsubscribeWebPush */
export type UnsubscribeWebPushInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

export type Visit = {
  __typename?: 'Visit';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  settled: Scalars['Boolean']['output'];
  /** 最も古いスタッフ呼び出しリクエスト */
  staffCallRequest: Maybe<StaffCallRequest>;
  tableName: Scalars['String']['output'];
};

export type UseShopUser_ShopUserFragment = { __typename?: 'ShopUser', id: string, email: string, shop: { __typename?: 'Shop', id: string, name: string } } & { ' $fragmentName'?: 'UseShopUser_ShopUserFragment' };

export type ShopUserQueryVariables = Exact<{ [key: string]: never; }>;


export type ShopUserQuery = { __typename?: 'Query', shopUser: (
    { __typename?: 'ShopUser' }
    & { ' $fragmentRefs'?: { 'UseShopUser_ShopUserFragment': UseShopUser_ShopUserFragment } }
  ) | null };

export type FetchCurrentEndpointSubscribedQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type FetchCurrentEndpointSubscribedQuery = { __typename?: 'Query', currentEndpointSubscribed: boolean };

export type CreateAuthSessionMutationVariables = Exact<{
  input: CreateAuthSessionInput;
}>;


export type CreateAuthSessionMutation = { __typename?: 'Mutation', createAuthSession: (
    { __typename?: 'ShopUser' }
    & { ' $fragmentRefs'?: { 'UseShopUser_ShopUserFragment': UseShopUser_ShopUserFragment } }
  ) };

export type FetchCoursesQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchCoursesQuery = { __typename?: 'Query', courses: Array<{ __typename?: 'Order', id: string, dailySequence: number, createdAt: string, orderItems: Array<{ __typename?: 'OrderItem', id: string, variation: string | null, name: string, price: number, subCategory: CatalogSubCategory, quantity: number, amount: number, status: OrderItemStatus }>, visit: { __typename?: 'Visit', id: string, createdAt: string, tableName: string, settled: boolean } }> };

export type CancelCourseMutationVariables = Exact<{
  orderItemId: Scalars['String']['input'];
}>;


export type CancelCourseMutation = { __typename?: 'Mutation', cancelCourse: boolean | null };

export type FetchOrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchOrdersQuery = { __typename?: 'Query', orders: Array<{ __typename?: 'Order', id: string, dailySequence: number, createdAt: string, orderItems: Array<{ __typename?: 'OrderItem', id: string, variation: string | null, name: string, price: number, subCategory: CatalogSubCategory, status: OrderItemStatus, quantity: number, amount: number }>, visit: { __typename?: 'Visit', id: string, createdAt: string, tableName: string, settled: boolean } }> };

export type ServeOrderItemMutationVariables = Exact<{
  orderItemId: Scalars['String']['input'];
}>;


export type ServeOrderItemMutation = { __typename?: 'Mutation', serveOrderItem: boolean | null };

export type CancelOrderItemMutationVariables = Exact<{
  orderItemId: Scalars['String']['input'];
}>;


export type CancelOrderItemMutation = { __typename?: 'Mutation', cancelOrderItem: boolean | null };

export type UndoOrderItemMutationVariables = Exact<{
  orderItemId: Scalars['String']['input'];
}>;


export type UndoOrderItemMutation = { __typename?: 'Mutation', undoOrderItem: boolean | null };

export type SubscribeWebPushMutationVariables = Exact<{
  input: SubscribeWebPushInput;
}>;


export type SubscribeWebPushMutation = { __typename?: 'Mutation', subscribeWebPush: boolean };

export type UnsubscribeWebPushMutationVariables = Exact<{
  input: UnsubscribeWebPushInput;
}>;


export type UnsubscribeWebPushMutation = { __typename?: 'Mutation', unsubscribeWebPush: boolean };

export type CallTestWebPushMutationVariables = Exact<{
  input: CallTestWebPushInput;
}>;


export type CallTestWebPushMutation = { __typename?: 'Mutation', callTestWebPush: boolean };

export type DestroyAuthSessionMutationVariables = Exact<{ [key: string]: never; }>;


export type DestroyAuthSessionMutation = { __typename?: 'Mutation', destroyAuthSession: boolean };

export type FetchCurrentShop_StaffCallRequestQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchCurrentShop_StaffCallRequestQuery = { __typename?: 'Query', currentShop: { __typename?: 'Shop', id: string, visits: Array<(
      { __typename?: 'Visit' }
      & { ' $fragmentRefs'?: { 'Visit_StaffCallRequestFragment': Visit_StaffCallRequestFragment } }
    )> } | null };

export type Visit_StaffCallRequestFragment = { __typename?: 'Visit', id: string, tableName: string, staffCallRequest: { __typename?: 'StaffCallRequest', id: string, createdAt: string, requestType: StaffCallRequestType } | null } & { ' $fragmentName'?: 'Visit_StaffCallRequestFragment' };

export type CompleteStaffCallRequestMutationVariables = Exact<{
  input: CompleteStaffCallRequestInput;
}>;


export type CompleteStaffCallRequestMutation = { __typename?: 'Mutation', completeStaffCallRequest: { __typename?: 'Visit', id: string } };

export const UseShopUser_ShopUserFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"UseShopUser_ShopUser"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ShopUser"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"shop"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode<UseShopUser_ShopUserFragment, unknown>;
export const Visit_StaffCallRequestFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"Visit_StaffCallRequest"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Visit"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"tableName"}},{"kind":"Field","name":{"kind":"Name","value":"staffCallRequest"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"requestType"}}]}}]}}]} as unknown as DocumentNode<Visit_StaffCallRequestFragment, unknown>;
export const ShopUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ShopUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"shopUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"UseShopUser_ShopUser"}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"UseShopUser_ShopUser"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ShopUser"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"shop"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode<ShopUserQuery, ShopUserQueryVariables>;
export const FetchCurrentEndpointSubscribedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"FetchCurrentEndpointSubscribed"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"token"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"currentEndpointSubscribed"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"token"},"value":{"kind":"Variable","name":{"kind":"Name","value":"token"}}}]}]}}]} as unknown as DocumentNode<FetchCurrentEndpointSubscribedQuery, FetchCurrentEndpointSubscribedQueryVariables>;
export const CreateAuthSessionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateAuthSession"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateAuthSessionInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createAuthSession"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"UseShopUser_ShopUser"}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"UseShopUser_ShopUser"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ShopUser"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"shop"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode<CreateAuthSessionMutation, CreateAuthSessionMutationVariables>;
export const FetchCoursesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"FetchCourses"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"courses"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"dailySequence"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"orderItems"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"variation"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"price"}},{"kind":"Field","name":{"kind":"Name","value":"subCategory"}},{"kind":"Field","name":{"kind":"Name","value":"quantity"}},{"kind":"Field","name":{"kind":"Name","value":"amount"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}},{"kind":"Field","name":{"kind":"Name","value":"visit"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"tableName"}},{"kind":"Field","name":{"kind":"Name","value":"settled"}}]}}]}}]}}]} as unknown as DocumentNode<FetchCoursesQuery, FetchCoursesQueryVariables>;
export const CancelCourseDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CancelCourse"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"orderItemId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"cancelCourse"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"orderItemId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"orderItemId"}}}]}}]}]}}]} as unknown as DocumentNode<CancelCourseMutation, CancelCourseMutationVariables>;
export const FetchOrdersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"FetchOrders"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"orders"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"dailySequence"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"orderItems"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"variation"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"price"}},{"kind":"Field","name":{"kind":"Name","value":"subCategory"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"quantity"}},{"kind":"Field","name":{"kind":"Name","value":"amount"}}]}},{"kind":"Field","name":{"kind":"Name","value":"visit"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"tableName"}},{"kind":"Field","name":{"kind":"Name","value":"settled"}}]}}]}}]}}]} as unknown as DocumentNode<FetchOrdersQuery, FetchOrdersQueryVariables>;
export const ServeOrderItemDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ServeOrderItem"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"orderItemId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"serveOrderItem"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"orderItemId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"orderItemId"}}}]}}]}]}}]} as unknown as DocumentNode<ServeOrderItemMutation, ServeOrderItemMutationVariables>;
export const CancelOrderItemDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CancelOrderItem"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"orderItemId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"cancelOrderItem"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"orderItemId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"orderItemId"}}}]}}]}]}}]} as unknown as DocumentNode<CancelOrderItemMutation, CancelOrderItemMutationVariables>;
export const UndoOrderItemDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UndoOrderItem"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"orderItemId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"undoOrderItem"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"orderItemId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"orderItemId"}}}]}}]}]}}]} as unknown as DocumentNode<UndoOrderItemMutation, UndoOrderItemMutationVariables>;
export const SubscribeWebPushDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SubscribeWebPush"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SubscribeWebPushInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"subscribeWebPush"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<SubscribeWebPushMutation, SubscribeWebPushMutationVariables>;
export const UnsubscribeWebPushDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UnsubscribeWebPush"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UnsubscribeWebPushInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"unsubscribeWebPush"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<UnsubscribeWebPushMutation, UnsubscribeWebPushMutationVariables>;
export const CallTestWebPushDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CallTestWebPush"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CallTestWebPushInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"callTestWebPush"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<CallTestWebPushMutation, CallTestWebPushMutationVariables>;
export const DestroyAuthSessionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DestroyAuthSession"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"destroyAuthSession"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[]}}]}]}}]} as unknown as DocumentNode<DestroyAuthSessionMutation, DestroyAuthSessionMutationVariables>;
export const FetchCurrentShop_StaffCallRequestDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"FetchCurrentShop_StaffCallRequest"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"currentShop"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"visits"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"actionRequired"},"value":{"kind":"BooleanValue","value":true}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Visit_StaffCallRequest"}}]}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"Visit_StaffCallRequest"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Visit"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"tableName"}},{"kind":"Field","name":{"kind":"Name","value":"staffCallRequest"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"requestType"}}]}}]}}]} as unknown as DocumentNode<FetchCurrentShop_StaffCallRequestQuery, FetchCurrentShop_StaffCallRequestQueryVariables>;
export const CompleteStaffCallRequestDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CompleteStaffCallRequest"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CompleteStaffCallRequestInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"completeStaffCallRequest"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<CompleteStaffCallRequestMutation, CompleteStaffCallRequestMutationVariables>;