// NOTE: https://www.figma.com/file/PTejBF8pzgtVlgCpt0DeiZ/Speria?type=design&node-id=9-6247&mode=design&t=lnIeDN4Zln71pRTr-4
//       基本的には、Chakra UIのデフォルトカラーに従います。

export const colors = {
  // colorSchema 用
  // https://www.tints.dev/primary/171923
  primary: {
    50: "#E3E5ED",
    100: "#C8CBDB",
    200: "#9399B8",
    300: "#5F6791",
    400: "#3B4059",
    500: "#171923",
    600: "#12141C",
    700: "#0E0F16",
    800: "#0A0B0F",
    900: "#040406",
  },
  accent: {
    50: "#F5F4F0",
    100: "#EDECE3",
    200: "#D9D6C4",
    300: "#C7C3A8",
    400: "#B3AE89",
    500: "#A19B6E",
    600: "#827D54",
    700: "#635F40",
    800: "#413E2A",
    900: "#222116",
  },
  negative: {
    50: "#FDEDED",
    100: "#FAD7D7",
    200: "#F5B3B3",
    300: "#EF8A8A",
    400: "#EA6666",
    500: "#E53E3E",
    600: "#CF1C1C",
    700: "#991515",
    800: "#670E0E",
    900: "#310707",
  },

  // 個別カラー
  // Figma内 で定義された variables と一致する
  core: {
    primary: "#171923",
    primaryInverted: "#FFF",
    accent: "#A19B6E",
    negative: "#E53E3E",
    warning: "#D69E2E",
    positive: "#38A169",
  },
  semantic: {
    background: {
      primary: "#FFF",
      secondary: "#F8F6F1",
      inverted: "#171923",
      opaque: "rgba(0, 0, 0, 0.36)",
    },
    contents: {
      primary: "#171923",
      secondary: "#4A5568",
      tertiary: "#A0AEC0",
      inverted: "#FFF",
      negative: "red.500",
    },
    border: {
      selected: "#171923",
      opaque: "gray.300",
      negative: "red.500",
    },
  },
};
