import { memo } from "react";

/* eslint-disable no-restricted-imports */
import {
  ChakraComponent,
  Text as ChakraText,
  TextProps as ChakraTextProps,
} from "@chakra-ui/react";

import { textStyles } from "./textStyles";
/* eslint-enable no-restricted-imports */

type Props = {
  textStyle?: keyof typeof textStyles;
} & ChakraTextProps;

/**
 * @deprecated `@chakra-ui/react` パッケージの Text を直接使用してください。
 * 型情報が生成されていない場合は、 pnpm gen:theme-typings を実行してください。
 */
export const Text = memo<Props>(({ children, ...props }) => {
  return <ChakraText {...props}>{children}</ChakraText>;
}) as ChakraComponent<"p", Props>;
