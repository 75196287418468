import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import { staticPath } from "src/utils/$path";

type ServiceWorkerProps = {
  ready: boolean;
};

const context = createContext<ServiceWorkerProps>({
  ready: false,
});

const { Provider } = context;

export const ServiceWorkerProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [ready, setReady] = useState<boolean>(false);

  useEffect(() => {
    const serviceWorker = navigator.serviceWorker;

    // NOTE: iPad では `ServiceWorker` が undifined となるため、この条件は必要
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!serviceWorker) return;

    (async () => {
      await serviceWorker.register(staticPath.firebase_messaging_sw_js);

      const registration = await serviceWorker.ready;

      setReady(registration.active?.state === "activated");

      registration.onupdatefound = async () => {
        await registration.update();
      };
    })();
  }, [ready]);

  return (
    <Provider
      value={{
        ready,
      }}
    >
      {children}
    </Provider>
  );
};

export const useServiceWorker = (): ServiceWorkerProps => {
  const { ready } = useContext(context);

  return {
    ready,
  };
};
