// NOTE: ひとまずは useFirebase の getToken で取得できる token を利用するが、iPad PWA ではしばしばエラーが発生するため、localStorage に保存した token を利用する

const storageKey = "pwa_token";

export const getPWAToken = ({ storage }: { storage?: Storage }) => {
  if (!storage) return null;

  return storage.getItem(storageKey);
};

export const setPWAToken = ({
  storage,
  token,
}: {
  storage: Storage;
  token: string;
}) => {
  storage.setItem(storageKey, token);
};

export const removePWAToken = ({ storage }: { storage?: Storage }) => {
  if (!storage) return;

  storage.removeItem(storageKey);
};
