import { NormalizedCacheObject } from "@apollo/client";
import { useMemo } from "react";

import { initializeApollo } from "src/gql/createApolloClient";

export const useApollo = (initialState?: NormalizedCacheObject) => {
  const store = useMemo(
    () => initializeApollo({ initialState }),
    [initialState]
  );

  return store;
};
