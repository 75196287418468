import { extendBaseTheme } from "@chakra-ui/react";
import { colors } from "@repo/ui/colors";
import { speriaTheme } from "@repo/ui/layout/speriaTheme";

const theme = extendBaseTheme(speriaTheme, {
  colors,
  components: {
    Switch: {
      baseStyle: {
        track: {
          bg: "#A0AEC0",
          _checked: {
            bg: colors.core.accent,
          },
        },
      },
    },
  },
});

export default theme;
