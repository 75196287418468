/* eslint-disable */
import * as types from './graphql';
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "fragment UseShopUser_ShopUser on ShopUser {\n  id\n  email\n  shop {\n    id\n    name\n  }\n}\n\nquery ShopUser {\n  shopUser {\n    ...UseShopUser_ShopUser\n  }\n}": types.UseShopUser_ShopUserFragmentDoc,
    "query FetchCurrentEndpointSubscribed($token: String!) {\n  currentEndpointSubscribed(token: $token)\n}": types.FetchCurrentEndpointSubscribedDocument,
    "mutation CreateAuthSession($input: CreateAuthSessionInput!) {\n  createAuthSession(input: $input) {\n    ...UseShopUser_ShopUser\n  }\n}": types.CreateAuthSessionDocument,
    "query FetchCourses {\n  courses {\n    id\n    dailySequence\n    createdAt\n    orderItems {\n      id\n      variation\n      name\n      price\n      subCategory\n      quantity\n      amount\n      status\n    }\n    visit {\n      id\n      createdAt\n      tableName\n      settled\n    }\n  }\n}\n\nmutation CancelCourse($orderItemId: String!) {\n  cancelCourse(input: {orderItemId: $orderItemId})\n}": types.FetchCoursesDocument,
    "query FetchOrders {\n  orders {\n    id\n    dailySequence\n    createdAt\n    orderItems {\n      id\n      variation\n      name\n      price\n      subCategory\n      status\n      quantity\n      amount\n    }\n    visit {\n      id\n      createdAt\n      tableName\n      settled\n    }\n  }\n}\n\nmutation ServeOrderItem($orderItemId: String!) {\n  serveOrderItem(input: {orderItemId: $orderItemId})\n}\n\nmutation CancelOrderItem($orderItemId: String!) {\n  cancelOrderItem(input: {orderItemId: $orderItemId})\n}\n\nmutation UndoOrderItem($orderItemId: String!) {\n  undoOrderItem(input: {orderItemId: $orderItemId})\n}": types.FetchOrdersDocument,
    "mutation SubscribeWebPush($input: SubscribeWebPushInput!) {\n  subscribeWebPush(input: $input)\n}\n\nmutation UnsubscribeWebPush($input: UnsubscribeWebPushInput!) {\n  unsubscribeWebPush(input: $input)\n}\n\nmutation CallTestWebPush($input: CallTestWebPushInput!) {\n  callTestWebPush(input: $input)\n}\n\nmutation DestroyAuthSession {\n  destroyAuthSession(input: {})\n}": types.SubscribeWebPushDocument,
    "query FetchCurrentShop_StaffCallRequest {\n  currentShop {\n    id\n    visits(actionRequired: true) {\n      ...Visit_StaffCallRequest\n    }\n  }\n}\n\nfragment Visit_StaffCallRequest on Visit {\n  id\n  tableName\n  staffCallRequest {\n    id\n    createdAt\n    requestType\n  }\n}\n\nmutation CompleteStaffCallRequest($input: CompleteStaffCallRequestInput!) {\n  completeStaffCallRequest(input: $input) {\n    id\n  }\n}": types.FetchCurrentShop_StaffCallRequestDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "fragment UseShopUser_ShopUser on ShopUser {\n  id\n  email\n  shop {\n    id\n    name\n  }\n}\n\nquery ShopUser {\n  shopUser {\n    ...UseShopUser_ShopUser\n  }\n}"): (typeof documents)["fragment UseShopUser_ShopUser on ShopUser {\n  id\n  email\n  shop {\n    id\n    name\n  }\n}\n\nquery ShopUser {\n  shopUser {\n    ...UseShopUser_ShopUser\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "query FetchCurrentEndpointSubscribed($token: String!) {\n  currentEndpointSubscribed(token: $token)\n}"): (typeof documents)["query FetchCurrentEndpointSubscribed($token: String!) {\n  currentEndpointSubscribed(token: $token)\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "mutation CreateAuthSession($input: CreateAuthSessionInput!) {\n  createAuthSession(input: $input) {\n    ...UseShopUser_ShopUser\n  }\n}"): (typeof documents)["mutation CreateAuthSession($input: CreateAuthSessionInput!) {\n  createAuthSession(input: $input) {\n    ...UseShopUser_ShopUser\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "query FetchCourses {\n  courses {\n    id\n    dailySequence\n    createdAt\n    orderItems {\n      id\n      variation\n      name\n      price\n      subCategory\n      quantity\n      amount\n      status\n    }\n    visit {\n      id\n      createdAt\n      tableName\n      settled\n    }\n  }\n}\n\nmutation CancelCourse($orderItemId: String!) {\n  cancelCourse(input: {orderItemId: $orderItemId})\n}"): (typeof documents)["query FetchCourses {\n  courses {\n    id\n    dailySequence\n    createdAt\n    orderItems {\n      id\n      variation\n      name\n      price\n      subCategory\n      quantity\n      amount\n      status\n    }\n    visit {\n      id\n      createdAt\n      tableName\n      settled\n    }\n  }\n}\n\nmutation CancelCourse($orderItemId: String!) {\n  cancelCourse(input: {orderItemId: $orderItemId})\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "query FetchOrders {\n  orders {\n    id\n    dailySequence\n    createdAt\n    orderItems {\n      id\n      variation\n      name\n      price\n      subCategory\n      status\n      quantity\n      amount\n    }\n    visit {\n      id\n      createdAt\n      tableName\n      settled\n    }\n  }\n}\n\nmutation ServeOrderItem($orderItemId: String!) {\n  serveOrderItem(input: {orderItemId: $orderItemId})\n}\n\nmutation CancelOrderItem($orderItemId: String!) {\n  cancelOrderItem(input: {orderItemId: $orderItemId})\n}\n\nmutation UndoOrderItem($orderItemId: String!) {\n  undoOrderItem(input: {orderItemId: $orderItemId})\n}"): (typeof documents)["query FetchOrders {\n  orders {\n    id\n    dailySequence\n    createdAt\n    orderItems {\n      id\n      variation\n      name\n      price\n      subCategory\n      status\n      quantity\n      amount\n    }\n    visit {\n      id\n      createdAt\n      tableName\n      settled\n    }\n  }\n}\n\nmutation ServeOrderItem($orderItemId: String!) {\n  serveOrderItem(input: {orderItemId: $orderItemId})\n}\n\nmutation CancelOrderItem($orderItemId: String!) {\n  cancelOrderItem(input: {orderItemId: $orderItemId})\n}\n\nmutation UndoOrderItem($orderItemId: String!) {\n  undoOrderItem(input: {orderItemId: $orderItemId})\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "mutation SubscribeWebPush($input: SubscribeWebPushInput!) {\n  subscribeWebPush(input: $input)\n}\n\nmutation UnsubscribeWebPush($input: UnsubscribeWebPushInput!) {\n  unsubscribeWebPush(input: $input)\n}\n\nmutation CallTestWebPush($input: CallTestWebPushInput!) {\n  callTestWebPush(input: $input)\n}\n\nmutation DestroyAuthSession {\n  destroyAuthSession(input: {})\n}"): (typeof documents)["mutation SubscribeWebPush($input: SubscribeWebPushInput!) {\n  subscribeWebPush(input: $input)\n}\n\nmutation UnsubscribeWebPush($input: UnsubscribeWebPushInput!) {\n  unsubscribeWebPush(input: $input)\n}\n\nmutation CallTestWebPush($input: CallTestWebPushInput!) {\n  callTestWebPush(input: $input)\n}\n\nmutation DestroyAuthSession {\n  destroyAuthSession(input: {})\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "query FetchCurrentShop_StaffCallRequest {\n  currentShop {\n    id\n    visits(actionRequired: true) {\n      ...Visit_StaffCallRequest\n    }\n  }\n}\n\nfragment Visit_StaffCallRequest on Visit {\n  id\n  tableName\n  staffCallRequest {\n    id\n    createdAt\n    requestType\n  }\n}\n\nmutation CompleteStaffCallRequest($input: CompleteStaffCallRequestInput!) {\n  completeStaffCallRequest(input: $input) {\n    id\n  }\n}"): (typeof documents)["query FetchCurrentShop_StaffCallRequest {\n  currentShop {\n    id\n    visits(actionRequired: true) {\n      ...Visit_StaffCallRequest\n    }\n  }\n}\n\nfragment Visit_StaffCallRequest on Visit {\n  id\n  tableName\n  staffCallRequest {\n    id\n    createdAt\n    requestType\n  }\n}\n\nmutation CompleteStaffCallRequest($input: CompleteStaffCallRequestInput!) {\n  completeStaffCallRequest(input: $input) {\n    id\n  }\n}"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;