import { getToken as getFirebaseToken, getMessaging } from "firebase/messaging";
import { useCallback, useEffect, useRef } from "react";

import { firebaseApp } from "../utils/firebase";

export const useFirebase = () => {
  const ready = useRef<boolean>(false);

  useEffect(() => {
    ready.current = true;
  }, [ready]);

  const getToken = useCallback(async (): Promise<string> => {
    if (!ready.current) return "";

    return await getFirebaseToken(getMessaging(firebaseApp), {
      vapidKey: process.env.NEXT_PUBLIC_VAPID_PUBLIC_KEY,
    });
  }, [ready]);

  return { getToken };
};
