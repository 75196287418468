import { ApolloProvider } from "@apollo/client";
import { FontsLoader } from "@repo/ui/fontsLoader";
import { AppProps } from "next/app";
import Head from "next/head";

import { useApollo } from "src/hooks/useApollo";
import { KitchenChakraProvider } from "src/providers/KitchenChakraProvider";
import { PWADeviceProvider } from "src/providers/PWADeviceProvider";
import { ServiceWorkerProvider } from "src/providers/ServiceWorkerProvider";
import { StaffCallRequestProvider } from "src/providers/StaffCallRequestProvider";

const App = ({ Component, pageProps }: AppProps) => {
  const client = useApollo(pageProps.initialApolloState);

  return (
    <>
      <Head>
        <title>Speria for Kitchen</title>
      </Head>

      <ApolloProvider client={client}>
        <KitchenChakraProvider>
          <ServiceWorkerProvider>
            <FontsLoader>
              <StaffCallRequestProvider>
                <PWADeviceProvider>
                  <Component {...pageProps} />
                </PWADeviceProvider>
              </StaffCallRequestProvider>
            </FontsLoader>
          </ServiceWorkerProvider>
        </KitchenChakraProvider>
      </ApolloProvider>
    </>
  );
};

export default App;
