import { Box, HStack, UseToastOptions } from "@chakra-ui/react";
import { AlertCircleIcon, CheckCircle2 } from "lucide-react";
// eslint-disable-next-line no-redeclare
import { Text } from "./text";

// ChakraProvider の toastOptions に渡すデフォルトのオプションです。
// ```
// <ChakraProvider
//   theme={theme}
//   toastOptions={{ defaultOptions: toastOptions }}
// >
//   {children}
// </ChakraProvider>
// ```
export const toastOptions: UseToastOptions = {
  position: "top",
  render: (props) => {
    const containerStylesMap = {
      success: {
        bgColor: "semantic.background.inverted",
      },
      error: {
        bgColor: "core.negative",
      },
    };
    const containerStyles =
      props.status && (props.status === "success" || props.status === "error")
        ? containerStylesMap[props.status]
        : null;

    return (
      <HStack py={3} px={4} borderRadius="2px" {...containerStyles}>
        <Box flexShrink={0}>
          {props.status === "error" ? (
            <AlertCircleIcon color="white" size="24px" />
          ) : (
            <CheckCircle2 color="white" size="24px" />
          )}
        </Box>
        <Text textStyle="Label/Medium" color="semantic.contents.inverted">
          {props.title}
        </Text>
      </HStack>
    );
  },
};
