import { InMemoryCache } from "@apollo/client";

export const createCache = () => {
  const cache = new InMemoryCache({
    typePolicies: {
      Query: {},
    },
  });

  return cache;
};
