import { extendTheme } from "@chakra-ui/react";
import { colors } from "../colors";
import { fonts } from "../fonts";
import { textStyles } from "../textStyles";

export const speriaTheme = extendTheme({
  colors,
  textStyles,
  fontSizes: {
    // デフォルトを 16px として計算
    xs: "0.75rem", // 12px
    sm: "0.875rem", // 14px
    md: "1rem", // 16px
    lg: "1.125rem", // 18px
    xl: "1.25rem", // 20px
    "2xl": "1.5rem", // 24px
    "3xl": "1.875rem", // 30px
    "4xl": "2.25rem", // 36px
    "5xl": "3rem", // 48px
    "6xl": "3.75rem", // 60px
    "7xl": "4.5rem", // 72px
    "8xl": "6rem", // 96px
    "9xl": "8rem", // 128px
  },
  components: {
    Heading: {
      variants: {
        heading_lg: {
          fontFamily: "Noto Sans JP",
          fontSize: "1.75rem",
          fontWeight: 700,
        },
        heading_md: {
          fontFamily: "Noto Sans JP",
          fontSize: "1.25rem",
          fontWeight: 700,
        },
        heading_sm: {
          fontFamily: "Noto Sans JP",
          fontSize: "1rem",
          fontWeight: 700,
        },
        ornament_lg: {
          fontFamily: "Noto Serif JP",
          fontSize: "1.75rem",
          fontWeight: 700,
        },
        ornament_md: {
          fontFamily: "Noto Serif JP",
          fontSize: "1.25rem",
          fontWeight: 700,
        },
        ornament_sm: {
          fontFamily: "Noto Serif JP",
          fontSize: "1rem",
          fontWeight: 700,
        },
      },
    },
    Text: {
      textStyles,
      variants: {
        paragraph_lg: {
          fontFamily: "Noto Sans JP",
          fontSize: "1.125rem",
          fontWeight: 400,
        },
        paragraph_md: {
          fontFamily: "Noto Sans JP",
          fontSize: "1rem",
          fontWeight: 400,
        },
        paragraph_sm: {
          fontFamily: "Noto Sans JP",
          fontSize: "0.875rem",
          fontWeight: 400,
        },
        paragraph_xs: {
          fontFamily: "Noto Sans JP",
          fontSize: "0.75rem",
          fontWeight: 400,
        },
        label_lg: {
          fontFamily: "Noto Sans JP",
          fontSize: "1.125rem",
          fontWeight: 500,
        },
        label_md: {
          fontFamily: "Noto Sans JP",
          fontSize: "1rem",
          fontWeight: 500,
        },
        label_sm: {
          fontFamily: "Noto Sans JP",
          fontSize: "0.875rem",
          fontWeight: 500,
        },
        menuDisplay_xl: {
          fontFamily: "Noto Serif JP",
          fontSize: "2rem",
          fontWeight: 400,
        },
        menuDisplay_lg: {
          fontFamily: "Noto Serif JP",
          fontSize: "1.75rem",
          fontWeight: 400,
        },
        menuDisplay_md: {
          fontFamily: "Noto Serif JP",
          fontSize: "1.5rem",
          fontWeight: 400,
        },
        menuDisplay_sm: {
          fontFamily: "Noto Serif JP",
          fontSize: "1.25rem",
          fontWeight: 400,
        },
        ornament_lg: {
          fontFamily: "Noto Serif JP",
          fontSize: "1.125rem",
          fontWeight: 400,
        },
        ornament_md: {
          fontFamily: "Noto Serif JP",
          fontSize: "1rem",
          fontWeight: 400,
        },
        ornament_sm: {
          fontFamily: "Noto Serif JP",
          fontSize: "0.875rem",
          fontWeight: 400,
        },
        ornament_xs: {
          fontFamily: "Noto Serif JP",
          fontSize: "0.75rem",
          fontWeight: 400,
        },
      },
    },
    Divider: {
      baseStyle: {
        opacity: 1,
      },
    },
    Button: {
      baseStyle: {
        fontFamily: "Noto Sans JP",
        fontWeight: 500,
        borderRadius: "2px",
      },
      variants: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        outline: (props: any) => {
          if (props.colorScheme === "primary") {
            return {
              _hover: {
                bg: "rgba(0, 0, 0, 0.04)",
              },
            };
          }
        },
      },
      defaultProps: {
        colorScheme: "primary",
      },
    },
    Switch: {
      defaultProps: {
        colorScheme: "primary",
      },
    },
  },
  fonts,
});
