import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { AppProps } from "next/app";

import { createCache } from "src/gql/createCache";

const GRAPHQL_ENDPOINT = process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT as string;

let apolloClient: ReturnType<typeof createApolloClient> | null;

export const createApolloClient = () => {
  const cache = createCache();

  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    cache,
    uri: GRAPHQL_ENDPOINT,
    credentials: "include",
  });
};

/**
 * SSR 時に Apollo Client で取得した state をフロントで復元する処理
 * 当面 SSR で Apollo Client を使用することはないので initialState が存在するケースは無いものと捉えて良い
 */
export const initializeApollo = ({
  initialState,
}: {
  initialState?: NormalizedCacheObject;
}) => {
  if (typeof window === "undefined") {
    const createdApolloClient = createApolloClient();

    if (initialState) {
      const existingCache = createdApolloClient.extract();

      createdApolloClient.cache.restore(
        Object.assign(existingCache, initialState)
      );
    }

    return createdApolloClient;
  }

  if (!apolloClient) {
    apolloClient = createApolloClient();
  }

  if (initialState) {
    const existingCache = apolloClient.extract();

    apolloClient.cache.restore(Object.assign(existingCache, initialState));
  }

  return apolloClient;
};

export const addApolloState = (
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: AppProps["pageProps"]
) => {
  if (pageProps.props) {
    pageProps.props.initialApolloState = client.cache.extract();
  }

  return pageProps;
};
