import { SystemStyleObject } from "@chakra-ui/react";

export const textStyles = {
  "Heading/Large": {
    fontFamily: "Noto Sans JP",
    fontSize: "1.75rem",
    fontWeight: "700",
  },
  "Heading/Medium": {
    fontFamily: "Noto Sans JP",
    fontSize: "1.25rem",
    fontWeight: "700",
  },
  "Heading/Small": {
    fontFamily: "Noto Sans JP",
    fontSize: "1rem",
    fontWeight: "700",
  },
  "Heading/Ornament/Large": {
    fontFamily: "Noto Serif JP",
    fontSize: "1.75rem",
    fontWeight: "700",
  },
  "Heading/Ornament/Medium": {
    fontFamily: "Noto Serif JP",
    fontSize: "1.25rem",
    fontWeight: "700",
  },
  "Heading/Ornament/Small": {
    fontFamily: "Noto Serif JP",
    fontSize: "1rem",
    fontWeight: "700",
  },
  "Label/XLarge": {
    fontFamily: "Noto Sans JP",
    fontSize: "1.25rem",
    fontWeight: "500",
  },
  "Label/Large": {
    fontFamily: "Noto Sans JP",
    fontSize: "1.125rem",
    fontWeight: "500",
  },
  "Label/Medium": {
    fontFamily: "Noto Sans JP",
    fontSize: "1rem",
    fontWeight: "500",
  },
  "Label/Small": {
    fontFamily: "Noto Sans JP",
    fontSize: "0.875rem",
    fontWeight: "500",
  },
  "Label/XSmall": {
    fontFamily: "Noto Sans JP",
    fontSize: "0.75rem",
    fontWeight: "500",
  },
  "Label/Ornament/XLarge": {
    fontFamily: "Noto Serif JP",
    fontSize: "1.25rem",
    fontWeight: "500",
  },
  "Label/Ornament/Large": {
    fontFamily: "Noto Serif JP",
    fontSize: "1.125rem",
    fontWeight: "500",
  },
  "Label/Ornament/Medium": {
    fontFamily: "Noto Serif JP",
    fontSize: "1rem",
    fontWeight: "500",
  },
  "Label/Ornament/Small": {
    fontFamily: "Noto Serif JP",
    fontSize: "0.875rem",
    fontWeight: "500",
  },
  "Paragraph/Large": {
    fontFamily: "Noto Sans JP",
    fontSize: "1.125rem",
    fontWeight: "400",
  },
  "Paragraph/Medium": {
    fontFamily: "Noto Sans JP",
    fontSize: "1rem",
    fontWeight: "400",
  },
  "Paragraph/Small": {
    fontFamily: "Noto Sans JP",
    fontSize: "0.875rem",
    fontWeight: "400",
  },
  "Paragraph/XSmall": {
    fontFamily: "Noto Sans JP",
    fontSize: "0.75rem",
    fontWeight: "400",
  },
  "Paragraph/Ornament/XLarge": {
    fontFamily: "Noto Serif JP",
    fontSize: "1.25rem",
    fontWeight: "400",
  },
  "Paragraph/Ornament/Large": {
    fontFamily: "Noto Serif JP",
    fontSize: "1.125rem",
    fontWeight: "400",
  },
  "Paragraph/Ornament/Medium": {
    fontFamily: "Noto Serif JP",
    fontSize: "1rem",
    fontWeight: "400",
  },
  "Paragraph/Ornament/Small": {
    fontFamily: "Noto Serif JP",
    fontSize: "0.875rem",
    fontWeight: "400",
  },
  "Paragraph/Ornament/XSmall": {
    fontFamily: "Noto Serif JP",
    fontSize: "0.75rem",
    fontWeight: "400",
  },
  "MenuDisplay/XLarge": {
    fontFamily: "Noto Serif JP",
    fontSize: "2rem",
    fontWeight: "400",
  },
  "MenuDisplay/Large": {
    fontFamily: "Noto Serif JP",
    fontSize: "1.5rem",
    fontWeight: "400",
  },
  "MenuDisplay/Medium": {
    fontFamily: "Noto Serif JP",
    fontSize: "1.125rem",
    fontWeight: "400",
  },
  "MenuDisplay/Small": {
    fontFamily: "Noto Serif JP",
    fontSize: "0.875rem",
    fontWeight: "400",
  },
} as const satisfies Record<string, SystemStyleObject>;
