import { useLazyQuery } from "@apollo/client";
import { useToast } from "@chakra-ui/react";
import { useCallback, useEffect, useRef, useState } from "react";

import { FetchCurrentEndpointSubscribedDocument } from "src/gql/__generated__/graphql";
import { useFirebase } from "src/hooks/useFirebase";
import {
  getPWAToken,
  removePWAToken,
  setPWAToken,
} from "src/store/localStorage/pwaToken";

type SubscribeResult = {
  result: {
    token: string | null;
    permission: NotificationPermission;
  } | null;
};

type WebPushSubscription = {
  ready: boolean;
  permission: NotificationPermission | null;
  subscribe: () => Promise<SubscribeResult>;
  fetchCurrentEndpointSubscribed: () => Promise<boolean>;
};

export const useWebPushSubscription = (): WebPushSubscription => {
  const ready = useRef<boolean>(false);
  const [permission, setPermission] = useState<NotificationPermission | null>(
    null,
  );
  const toast = useToast();
  const { getToken } = useFirebase();

  useEffect(() => {
    ready.current = true;
  }, [ready]);

  const subscribe = useCallback(async (): Promise<SubscribeResult> => {
    if (!ready.current) return { result: null };

    const requestPermission =
      Notification.permission === "granted"
        ? "granted"
        : await Notification.requestPermission();

    setPermission(requestPermission);
    switch (requestPermission) {
      case "granted": {
        const serviceWorker = navigator.serviceWorker;

        const registration = await serviceWorker.ready;

        await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: process.env.NEXT_PUBLIC_VAPID_PUBLIC_KEY,
        });

        const token = await getToken();

        setPWAToken({ storage: localStorage, token });

        return {
          result: {
            token,
            permission: requestPermission,
          },
        };
      }
      case "denied":
        removePWAToken({ storage: localStorage });

        return {
          result: {
            token: null,
            permission: requestPermission,
          },
        };
      case "default":
        return {
          result: {
            token: null,
            permission: requestPermission,
          },
        };
      default:
        return { result: null };
    }
  }, [ready, setPermission, getToken]);

  const [fetch] = useLazyQuery(FetchCurrentEndpointSubscribedDocument, {
    fetchPolicy: "network-only",
    onError: (error) => {
      toast({ title: error.message, status: "error" });
    },
  });

  const storageToken =
    typeof localStorage !== "undefined"
      ? getPWAToken({ storage: localStorage })
      : null;

  const fetchCurrentEndpointSubscribed =
    useCallback(async (): Promise<boolean> => {
      if (!ready.current) return false;

      if (Notification.permission !== "granted") {
        if (!storageToken) return false;

        const { data } = await fetch({
          variables: {
            token: storageToken,
          },
        });

        return data?.currentEndpointSubscribed || false;
      }

      try {
        const token = await getToken();
        const { data } = await fetch({
          variables: {
            token,
          },
        });

        return data?.currentEndpointSubscribed || false;
      } catch (error) {
        // NOTE: catch されるエラーはすべて getToken によるエラーとなるはず
        //       serviceWorker 登録直後等はエラーを吐くことがあるため、これを無視する
        console.error(error);

        if (!storageToken) return false;

        const { data } = await fetch({
          variables: {
            token: storageToken,
          },
        });

        return data?.currentEndpointSubscribed || false;
      }
    }, [ready, fetch, getToken, storageToken]);

  return {
    ready: ready.current,
    permission,
    subscribe,
    fetchCurrentEndpointSubscribed,
  };
};
